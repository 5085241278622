export class ReadyPromise {
    constructor() {
        this.ready = () => {
            this.isReady = true;
            if (this.resolve)
                this.resolve();
        };
        this.isReady = false;
        this.promise = new Promise((resolve) => {
            this.resolve = resolve;
            if (this.isReady)
                resolve();
        });
    }
    then(cb) {
        this.promise.then(cb);
        return this;
    }
}
