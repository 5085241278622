/* eslint-disable prefer-spread */
import { ReadyPromise } from "@autocorp/web-core-utils/lib/ReadyPromise";
import { keys } from "ts-transformer-keys";
import { SETTINGS } from "./constants";
import type {
    SentrySdk,
    SentryIntegrations,
    SentryOptions,
    CustomIntegrations,
    LoadCallback,
    ArrayType,
    SdkQueueEntry,
    InitFunction,
    OnLoadFunction,
    ISentryClient,
    ClientArgs,
    IBrowserOptions,
} from "./types";
import type { WrapSentrySdk } from "./types";

declare global {
    interface Window {
        Sentry: Readonly<ISentryClient>;
        [SETTINGS]?: IBrowserOptions;
    }
}

const wrapSentrySdkKeys = keys<WrapSentrySdk>();

function createSentryClient(): Readonly<ISentryClient> {
    let isReady = false;
    let isDisabled = false;
    let sentryOptions: SentryOptions = window[SETTINGS] || {};
    let sentrySdk: SentrySdk;
    let customIntegrations: CustomIntegrations = {};
    let sentryIntegrations: Partial<SentryIntegrations> = {};
    const loadCallbacks: LoadCallback[] = [];
    const messageQueue: SdkQueueEntry[] = [];

    const isPreload = () => {
        return !isReady && !isDisabled;
    };
    const disable = (val = true) => {
        isDisabled = val;
    };

    let _sdkReady: ReadyPromise;
    const awaitSdk = (): ReadyPromise => {
        return _sdkReady = _sdkReady || new ReadyPromise();
    };

    const loadSdk = () => (
        import(
            "./sentry/sentrySdk"
            /* webpackChunkName: "sentrySdk" */
        ).then((sentryModule) => {
            sentrySdk = sentryModule;
        }).finally(() => {
            awaitSdk().ready();
        })
    );

    const loadIntegrations = () => (
        import(
            "./sentry/sentryIntegrations"
            /* webpackChunkName: "sentryIntegrations" */
        ).then((integrationsModule) => {
            sentryIntegrations = {
                ...sentryIntegrations,
                ...integrationsModule,
            };
            return awaitSdk();
        }).then(() => {
            sentryIntegrations = {
                ...sentryIntegrations,
                ...sentrySdk?.Integrations || {},
            };
        })
    );

    const onReady = () => {
        const options = {
            ...sentryOptions || {},
            ...(!sentryOptions.integrations && Object.keys(customIntegrations).length > 0
                ? {
                    integrations: Object.entries(customIntegrations)
                        .reduce((acc, [key, props]) => {
                            const integrationName = key as keyof SentryIntegrations;
                            const SentryIntegration = sentryIntegrations[integrationName];
                            if (SentryIntegration) {
                                acc.push(new SentryIntegration(props as any));
                            }
                            return acc;
                        }, [] as ArrayType<Required<SentryOptions>["integrations"]>),
                } : {}
            ),
        };

        sentrySdk.init(options);
        loadCallbacks.forEach((cb) => cb(sentrySdk));
        messageQueue.forEach(processQueueEntry);
        messageQueue.length = 0;

        loadCallbacks.length = 0;
        messageQueue.length = 0;
    };
    const ready = () => {
        isDisabled = false;
        isReady = true;
        onReady();
    };

    let loading = false;
    const init: InitFunction = (newOptions = sentryOptions, integrations) => {
        const {
            disabled = false,
            ...options
        } = (typeof newOptions === "function"
            ? newOptions({ disabled: isDisabled, ...sentryOptions })
            : newOptions
        ) || {};
        sentryOptions = options;
        disable(disabled);
        if (integrations) {
            customIntegrations = integrations;
        }
        if (isPreload() && !loading) {
            Promise.all([
                loadSdk(),
                (!sentryOptions.integrations && integrations
                    && loadIntegrations()
                    || Promise.resolve()
                ),
            ])
                .then(ready)
                .catch((err) => {
                    if (err) console.error(err);
                    disable();
                })
                .finally(() => {
                    loading = false;
                });
            loading = true;
        }
        if (isReady) {
            ready();
        }
    };

    const onLoad: OnLoadFunction = (cb: LoadCallback) => {
        if (isReady) return cb(sentrySdk);
        loadCallbacks.push(cb);
    };

    const processQueueEntry = (entry: SdkQueueEntry) => {
        if ("p" in entry) {
            return window.onunhandledrejection?.apply(window, [entry.p]);
        }
        if ("e" in entry) {
            return window.onerror?.apply(window, entry.e);
        }

        if (entry.f in sentrySdk) {
            // eslint-disable-next-line prefer-spread
            (sentrySdk[entry.f] as any).apply(sentrySdk, entry.a);
        }
    };
    const queue = (entry: SdkQueueEntry) => {
        if (isDisabled) return;
        if (isReady) return processQueueEntry(entry);
        if (!isReady) init();
        messageQueue.push(entry);
    };

    const oldOnerror = window.onerror;
    const oldOnunhandledrejection = window.onunhandledrejection;

    window.onerror = (...args) => {
        if (isPreload()) {
            queue({
                e: args.slice() as Parameters<NonNullable<Window["onerror"]>>,
            });
        }

        if (oldOnerror) oldOnerror.apply(window, args);
    };

    window.onunhandledrejection = (e: any) => {
        if (isPreload()) {
            queue({
                p: "reason" in e
                    ? e.reason
                    : "detail" in e && "reason" in e.detail
                        ? e.detail.reason
                        : e,
            });
        }
        if (oldOnunhandledrejection) oldOnunhandledrejection.apply(window, [e]);
    };

    return Object.freeze(
        wrapSentrySdkKeys.reduce((acc, key) => {
            acc[key] = (...args: ClientArgs<typeof key>) => {
                queue({
                    f: key,
                    a: args,
                });
            };
            return acc;
        }, {
            init,
            onLoad,
        } as ISentryClient),
    );
}

window.Sentry = createSentryClient();